import React, { useState, useEffect } from "react";
import "./WelcomeModal.css";

function WelcomeModal() {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(true);
  }, []);

  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={() => setIsOpen(false)}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="modal-container">
          <div className="modal-text">
            {/* <h2>Weź udział w wyjątkowym wydarzeniu ze świata pielęgnacji!</h2> */}
            <p>
              Weź udział w wyjątkowym wydarzeniu ze świata pielęgnacji! Odbierz{" "}
              <span>bezpłatne prezenty</span>, skorzystaj z{" "}
              <span>wiedzy ekspertów</span>, sprawdź{" "}
              <span>stan swojej skóry</span> i poznaj produkty dopasowane do jej{" "}
              <span>indywidualnych potrzeb</span>.
            </p>
            <div className="event-info">
              <p className="event-free">UDZIAŁ W WYDARZENIU JEST BEZPŁATNY.</p>
              <p className="register-info">
                Zarejestruj się już dziś - liczba miejsc ograniczona!
              </p>
            </div>
          </div>
          <a
            href="/#form"
            className="register-button"
            onClick={() => setIsOpen(false)}
          >
            ZAREJESTRUJ SIĘ
          </a>
        </div>
      </div>
    </div>
  );
}

export default WelcomeModal;

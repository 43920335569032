import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-notifications/lib/notifications.css";
import App from "./App";
import WelcomeModal from "./components/WelcomeModal/WelcomeModal";
import { UserProvider } from "./context/UserContext";
import { NotificationContainer } from "react-notifications";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <UserProvider>
    <WelcomeModal />
    <App />
    <NotificationContainer />
  </UserProvider>
);
